import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import { Cadastro } from './signup';
import { Login } from './login'
import { AuthRoute, UnAuthRoute } from '../components'
import { Home } from './home';
import { MyBids } from './my-bids';
import { NewBid } from './my-bids/new';
import { Bids } from './bids';
import { SelectBidAccount } from './my-bids/account-select';
import { Profile } from './profile';
import { Cards } from './cards';
import { NewCard } from './cards/new';
import { NewAccount } from './accounts/new';
import { Orders } from './orders';
import { NewOrder } from './bids/order';
import { Payments } from './payments';
import { Payment } from './payments/id';
import { MyPayments } from './my-payments';
import { MyAccounts } from './accounts';
import { Account } from './accounts/id';
import { Users } from './users';
import { User } from './users/id';
import { Order } from './orders/id';
import { Bid } from './bids/id';
import { Programs } from './programs';
import { Welcome } from './welcome';
import { ForgotPassword } from './forgot-password';
import { NewPassword } from './new-password';
import { MyBid } from './my-bids/id';
import { Dashboard } from './dashboard';
import { CreateOrder } from './orders/new';
import { Help } from './help';
import { NewHelp } from './help/new';
import { HelpId } from './help/id';
import { SupportTickets } from './support-tickets';
import { SupportTicket } from './support-tickets/id';
import { CardId } from './cards/id';
import { Banking } from './banking';
import { Agencies } from './agencies';
import { LandingPage } from './landing-page';
import { Leads } from './leads';
import { Lead } from './leads/id';
import { Coupons } from './coupons';
import { MyReferrals } from './my-referrals';
import { Referrals } from './referrals';
import { LPOrder } from './lp-order';
import { Customers } from './customers';
import { CustomerDash } from './customer-dash';
import { CustomerAuthContextProvider } from '../context/customerAuth';
import { AuthContextProvider } from '../context/auth';

export const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <AuthContextProvider>
            <UnAuthRoute>
                <Login />
            </UnAuthRoute>
        </AuthContextProvider>
      ),
    },
    {
      path: "/esqueci-minha-senha",
      element: (
        <AuthContextProvider>
            <UnAuthRoute>
                <ForgotPassword />
            </UnAuthRoute>
        </AuthContextProvider>
      ),
    },
    {
      path: "/nova-senha",
      element: (
        <AuthContextProvider>
        <UnAuthRoute>
            <NewPassword />
        </UnAuthRoute>
        </AuthContextProvider>
      ),
    },
    {
        path: '/cadastro',
        element: (
            <AuthContextProvider>
            <UnAuthRoute>
                <Cadastro />
            </UnAuthRoute>
            </AuthContextProvider>
          ),
    },
    {
        path: '/home',
        element: (
            <AuthContextProvider>
                <AuthRoute>
                    <Home />
                </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/minhas-ofertas',
        element: (
            <AuthContextProvider>
                <AuthRoute>
                    <MyBids />
                </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/minhas-ofertas/nova',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <NewBid />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/admin/ofertas',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <Bids />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/minhas-ofertas/:id/selecionar-conta',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <SelectBidAccount />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/minhas-ofertas/:id',
        element: (
            <AuthContextProvider>
                <AuthRoute>
                    <MyBid />
                </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/meu-perfil',
        element: (
            <AuthContextProvider>
                <AuthRoute>
                    <Profile />
                </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/admin/cartoes',
        element: (
            <AuthContextProvider>
                <AuthRoute>
                    <Cards />
                </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/admin/cartoes/novo',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <NewCard />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/admin/cartoes/:id',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <CardId />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/contas/nova',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <NewAccount />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/admin/emissoes',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <Orders />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/admin',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <Dashboard />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/admin/ofertas/:id/emissao',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <NewOrder />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/admin/pagamentos',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <Payments />
            </AuthRoute></AuthContextProvider>
        )
    },
    {
        path: '/admin/pagamentos/:id',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <Payment />
            </AuthRoute></AuthContextProvider>
        )
    },
    {
        path: '/meus-pagamentos',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <MyPayments />
            </AuthRoute></AuthContextProvider>
        )
    },
    {
        path: '/minhas-contas',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <MyAccounts />
            </AuthRoute></AuthContextProvider>
        )
    },
    {
        path: '/minhas-contas/:id',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <Account />
            </AuthRoute></AuthContextProvider>
        )
    },
    {
        path: '/admin/usuarios',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <Users />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/admin/usuarios/:id',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <User />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/admin/emissoes/nova',
        element: (

            <AuthContextProvider>
            <AuthRoute>
                <CreateOrder />
            </AuthRoute></AuthContextProvider>
        )
    },
    {
        path: '/admin/emissoes/:id',
        element: (

            <AuthContextProvider>
            <AuthRoute>
                <Order />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/admin/ofertas/:id',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <Bid />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/admin/financeiro',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <Banking />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/admin/programas',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <Programs />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/bem-vindo',
        element: (

            <AuthContextProvider>
            <AuthRoute>
                <Welcome />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/ajuda',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <Help />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/ajuda/novo',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <NewHelp />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/ajuda/:id',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <HelpId />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/admin/chamados',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <SupportTickets />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/admin/chamados/:id',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <SupportTicket />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/admin/agencias',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <Agencies />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/admin/leads',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <Leads />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/admin/leads/:id',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <Lead />
            </AuthRoute></AuthContextProvider>
        )
    },
    {
        path: '/admin/cupons',
        element: (
            <AuthContextProvider>
            <AuthRoute>
                <Coupons />
            </AuthRoute></AuthContextProvider>
        )
    },
    {
        path: '/minhas-indicacoes',
        element: (

            <AuthContextProvider>
            <AuthRoute>
                <MyReferrals />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/admin/indicacoes',
        element: (

            <AuthContextProvider>
            <AuthRoute>
                <Referrals />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/admin/captacao',
        element: (
            <LandingPage />
        )
    },
    {
        path: '/admin/emitir',
        element: (
            <LPOrder />
        )
    },
    {
        path: '/admin/clientes',
        element: (

            <AuthContextProvider>
            <AuthRoute>
                <Customers />
            </AuthRoute>
            </AuthContextProvider>
        )
    },
    {
        path: '/painel-cliente',
        element: (
            <CustomerAuthContextProvider>
                <CustomerDash />
            </CustomerAuthContextProvider>
        )
    }
]);
  
// export const router = createBrowserRouter([
//     {
//       path: "*",
//       element: (
//         <div>
//             <h1>Em Manutenção...</h1>
//         </div>
//       ),
//     },
// ])


const Provider = () => {
    return (
        <RouterProvider router={router} />
    )
}

export default Provider
